/**
 * /* Forms
 *
 * @format
 */

.form-group {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;
  .input-group-text {
    border-color: #eee;
    padding: 0.575rem 0.75rem;
    color: #5395ff;
  }
  button {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
}

.custom-control {
  .custom-control-label {
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.form-control:placeholder {
  color: #888;
}
.form-control {
  border: 1px solid #eee;
  background-color: #f9fafc;
  font-size: $input-font-size;
}
.form-control-lg {
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}

select {
  &.form-control {
    padding: 0.4375rem 0.75rem;
    border: 1px solid #eee;
    border-radius: 3px;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color, $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: 0.5rem;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}

.form-check-input {
  position: absolute;
  margin-top: 0.2rem;
  margin-left: 0rem;
}
