.content-wrapper {
  padding: 0 !important;
}

.market-max-w {
  max-width: 64rem;
}

.app-content-wrap {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.app-content {
  padding: 2px 15px;
  width: calc(100% / 4);
}

.app-content-small {
  padding: 0px 15px;
  width: calc(100% / 4);
}

.user-content {
  padding: 0px 15px;
  width: calc(100% / 3);
}

.law-app-content {
  padding: 2px 15px;
  width: calc(100% / 2);
}

.aiso-card {
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border: 3px solid rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5) !important;
  border-radius: 20px;
  height: 100%;
  word-wrap: break-word;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.market-card-footer {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-top: 3px solid rgba(255, 255, 255, 0.4) !important;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1) !important;
}

/* img 50% */
.aiso-card-img {
  display: flex;
  flex-flow: row wrap;
}

.aiso-content-img-left2,
.aiso-content-img-left {
  width: calc(100% / 2);
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
  object-fit: cover;
  background-size: cover;
  border-top-left-radius: 20px;
}

.aiso-content-img-left2 {
  border-bottom-left-radius: 20px;
}

.aiso-content-img-right2,
.aiso-content-img-right {
  width: calc(100% / 2);
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
  object-fit: cover;
  background-size: cover;
  border-top-right-radius: 20px;
}

.aiso-content-img-right2 {
  border-bottom-right-radius: 20px;
}

.aiso-content-img-left2 > img,
.aiso-content-img-left > img,
.aiso-content-img-right2 > img,
.aiso-content-img-right > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
}

.aiso-content-img-left2 > div,
.aiso-content-img-left > div,
.aiso-content-img-right2 > div,
.aiso-content-img-right > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
}

/* 앱만들기 아이콘 이미지 */
.aiso-content-img-70 {
  width: calc(100% / 2);
  position: relative;
  padding-bottom: 50%;
}

.aiso-content-img-70 > img {
  position: absolute;
  object-fit: cover;
  overflow: hidden;
  width: 70%;
  height: 70%;
  top: 50%;
  margin-top: -35%;
  left: 50%;
  margin-left: -35%;
}

/* img Slide */
.slick-slider.aiso-card-img2 > button {
  display: none !important;
}

.aiso-card-img2 .slick-track .slick-slide > div {
  position: relative !important;
  width: 100% !important;
  padding-bottom: 100% !important;
  overflow: hidden !important;
}

.aiso-card-img2 .slick-track .slick-slide > div > img {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.slick-dots {
  bottom: 5px !important;
}

.slick-dots li button:before {
  color: #ccc;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: #5395ff;
  opacity: 1;
}

/*  */
.aiso-card-footer {
  border-top: 1px solid #eee;
  padding: 0.5rem 1rem;
}

/*******************************************************/
/* 아이소 데이터 카드 */
.aiso-data-card {
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border: 3px solid rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5) !important;
  border-radius: 20px;
  word-wrap: break-word;
  width: 100%;
}
.data-img-radius {
  height: 227px;
  background-color: #fff;
  border-radius: 17px 0 0 17px !important;
  border-right: 3px solid rgba(255, 255, 255, 0.1);
  border-bottom: none;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.2);
}
.data-card-footer {
  border-top: 3px solid rgba(255, 255, 255, 0.4) !important;
}
@media (max-width: 579px) {
  .data-img-radius {
    height: 300px;
    border-radius: 17px 17px 0 0 !important;
    border-right: none;
    border-bottom: 3px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
  .data-card-footer {
    background-color: rgba(255, 255, 255, 0.4) !important;
    border-top: 3px solid rgba(255, 255, 255, 0.4) !important;
    border-radius: 0 0 20px 0;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  }
}

@media (max-width: 1500px) {
  .app-content-small {
    padding: 5px 10px;
    width: calc(100% / 3);
  }
}

@media (max-width: 1280px) {
  .market-max-w {
    max-width: 50rem;
  }

  .app-content-small,
  .app-content {
    padding: 5px 10px;
    width: calc(100% / 3);
  }
}

@media (max-width: 768px) {
  .app-content-small,
  .app-content {
    width: calc(100% / 2);
  }
}

@media (max-width: 480px) {
  .app-content-small,
  .app-content {
    width: 100%;
  }

  .MuiStepper-root {
    padding: 15px 0 !important;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
