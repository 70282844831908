/** @format */
/* 앱 카드 그리드 관련 스타일은 제외 */
/* 앱 카드 관련 css 는 aisoContentCard 파일입니다 */
/* 앱 관련 페이지+디테일 페이지 */

/*=====================================================*/
/*========== Market Category ==========*/
.market-category > ul > li {
  margin: 0 7px;
}
.market-category > ul > li:first-of-type {
  margin-left: 0;
}
.market-category > ul > li > button {
  border: 1px solid #eee !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  font-size: 13px;
  padding: 12px 17px;
}

/*========== Data Category ==========*/
.data-category ul li {
  margin: 7px 3px;
}
.data-category ul li:first-of-type {
  margin-left: 0;
}
.data-category ul li button {
  border: 1px solid #eee !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  font-size: 13px;
  padding: 12px 17px;
}

/*=====================================================*/
/*========== App Detail Page ==========*/
/* 업로드 영역 */
.upload-circle-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-circle {
  border: 3px solid #5395ff;
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  max-width: 70px;
  max-height: 70px;
  position: relative;
  cursor: pointer;
}

.upload-circle:after {
  border: 1px solid #5395ff;
  border-radius: 9999px;
  content: "";
  display: block;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: scale(1);
  transform-origin: center center;
  animation: 1300ms upload-btn forwards ease-in-out infinite;
  opacity: 0;
}

@keyframes upload-btn {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  0% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 767px) {
  .upload-circle {
    border: 2px solid #5395ff;
    width: 3rem;
    height: 3rem;
  }
}
@media (max-width: 480px) {
}

/* .no-drag {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
} */

.no-drag {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
