/*=====================================================*/
/*========== 로그인 박스 ==========*/
.login_box {
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border: 4px solid rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5) !important;
  border-radius: 30px !important;
}

.basic_input > input {
  height: 50px;
  color: #000;
  font-size: 16px !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
  border-radius: 99px !important;
}
.basic_input > input::placeholder {
  color: #fff;
  font-size: 16px !important;
}
