/** @format */

.header-menu-back {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  font-family: "GmarketSansMedium";
  margin: 0 1em;
  cursor: pointer;
  &:hover {
    color: #5395ff;
    font-family: "GmarketSansMedium";
    transition: ease-in-out 0.25s;
    -webkit-transition: ease-in-out 0.25s;
  }
  &:active {
    color: #5395ff;
    font-family: "GmarketSansMedium";
  }
}

.header-menu {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  // border-bottom: 2px solid #fff;
  font-family: "GmarketSansMedium";
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333;

  &:hover {
    // border-bottom: 3px solid #5395ff;
    font-weight: bold;
    color: #5395ff;
    transition: ease-in-out 0.25s;
    -webkit-transition: ease-in-out 0.25s;
  }

  &:active {
    border-bottom: 3px solid #5395ff;
    font-weight: bold;
    color: #5395ff;
    transition: ease-in-out 0.25s;
    -webkit-transition: ease-in-out 0.25s;
  }
  @media (max-width: 1069px) {
    padding: 7px 5px;
  }
}

.header-menu-active {
  font-family: "GmarketSansMedium";
  font-size: 15px;
  padding: 7px 10px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  // text-align: left;
  color: #5395ff;
  font-weight: bold;
  transition: ease-in-out 0.25s;
  -webkit-transition: ease-in-out 0.25s;
}
.header-sign-up {
  font-family: "GmarketSansMedium";
  font-size: 14px;
  padding: 7px 10px;
  border-bottom: 2px solid #fff;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  // text-align: left;
  color: #333;
}

.header-sign-in {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-bottom: 2px solid #fff;
  font-family: "GmarketSansMedium";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333;
}
.header-sign-up:hover,
.header-sign-in:hover {
  border-bottom: 2px solid #5395ff;
  color: #5395ff;
  transition: ease-in-out 0.25s;
  -webkit-transition: ease-in-out 0.25s;
}
.header-sign-up:active,
.header-sign-in:active {
  border-bottom: 2px solid #5395ff;
  color: #5395ff;
  transition: ease-in-out 0.25s;
  -webkit-transition: ease-in-out 0.25s;
}
