/** @format */

/*=====================================================*/
/*========== Dashboard Page ==========*/
/* Chart */
.recharts-cartesian-axis-tick-line,
.recharts-cartesian-axis-line {
  stroke: #ddd !important;
}
.recharts-cartesian-grid-horizontal > line {
  stroke: #ddd !important;
}
.recharts-cartesian-grid-vertical > line {
  stroke: none !important;
}
.recharts-text {
  fill: #888 !important;
  font-size: 10px;
}

/*=====================================================*/
/*========== App Detail Page ==========*/
/* Menu */
.app-detail-menu {
  color: #555;
  font-weight: 600;
  font-size: 14px;
  padding: 0.75rem 2rem;
}
.app-detail-menu:hover {
  transition: ease-in-out 0.3s;
  background-color: #fff;
  color: #5395ff;
  border-radius: 3px;
}
.app-detail-menu a:active {
  transition: ease-in-out 0.3s;
  background-color: #fff;
  color: #5395ff;
  border-radius: 3px;
}
.app-detail-menu-admin {
  color: #333;
  font-weight: 600;
  font-size: 15px;
  border-radius: 3px;
  padding: 0.75rem 2rem;
}
.app-detail-menu-admin:hover {
  background-color: #fff;
  transition: ease-in-out 0.3s;
  color: #5395ff;
  border-radius: 3px;
}
/*=====================================================*/
/*========== App Detail Page ==========*/
/* comment */
.comment-accordion {
  border-bottom: 0 !important;
}
.comment-accordion > .card-body {
  padding-right: 0 !important;
}
/*=====================================================*/
/*========== AISO 수익페이지 Scroll ==========*/
.noscroll-body-h,
.scroll-body-h {
  min-height: 100%;
  max-height: calc(100vh - 19.5rem);
}
.scroll-body-h > div {
  overflow-y: scroll;
}
@media (max-width: 768px) {
  .scroll-body-h {
    max-height: calc(100vh - 10rem);
  }
}

/*=====================================================*/
/*========== 테이블 상태 표시 ==========*/
.status {
  margin-bottom: 0 !important;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 2px !important;
  border-radius: 3px;
}
.gray-status {
  background-color: rgb(240, 240, 240);
  color: #555;
}
.danger-status {
  background-color: #ffe6e6;
  color: #f96868;
}
.primary-status {
  background-color: #e2edff;
  color: #5395ff;
}
