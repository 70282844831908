/**
 * /* Layouts
 *
 * @format
 */

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .nav {
        overflow: visible;
        margin-left: 0;
        margin-right: 0;
        .nav-item {
          position: relative;
          .nav-link {
            display: block;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            text-align: center;
            position: static;
            .menu-title,
            .badge,
            .menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 0px 0px);
              background: $sidebar-light-menu-hover-bg;

              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &.menu-expanded {
              border-radius: 0.437rem;
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: #fff;
                border: 1px solid #ddd;
                border-radius: 5px;
                @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-light-menu-active-bg;
                }
                padding: 0.5rem 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                }
                @at-root #{selector-append(".sidebar-dark", &)} {
                  color: $white;
                }
                &:after {
                  display: none;
                }
                &:hover {
                  font-weight: bold;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              background: $icon-only-menu-bg-light;
              @include border-radius(0 0 5px 0);
              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-light-bg;
              }
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
                @include border-radius(0 0 0 5px);
              }
            }
          }
        }
        &:not(.sub-menu) {
          .nav-item {
            &.active {
              border-radius: 0;
            }
            &:hover {
              .nav-link {
                border-radius: 0;
              }
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              padding-left: 20px;
            }
          }
          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-right: auto;
              margin-left: 0;
              .nav-link {
                text-align: right;
                &:before {
                  left: 0;
                  right: unset;
                }
              }
            }
          }
        }
      }
      .sidebar-actions {
        display: none;
      }
    }
    .main-panel {
      width: calc(100% - #{$sidebar-width-icon});
    }
  }
}
