/** @format */

/*=====================================================*/
/* Font */

@font-face {
  font-family: "GmarketSansLight";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.font-gmarket-light {
  font-family: "GmarketSansLight" !important;
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.font-gmarket-medium {
  font-family: "GmarketSansMedium" !important;
}

@font-face {
  font-family: "GmarketSansBold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TMONBlack";
  src: url("TMONBlack.ttf") format("truetype");
}

.font-gmarket-bold {
  font-family: "GmarketSansBold" !important;
}

.TMONBlack {
  font-family: "TMONBlack" !important;
}

/*=====================================================*/
/* dev-page-content */
/* .dev-page-content {
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
} */

/*=====================================================*/
/* main-Home */
.page-1-h {
  height: calc(100vh - 100px);
}

.first-page-content {
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.page-content {
  max-width: 1700px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.MuiSelect-select {
  border-radius: 10px !important;
}
.visitor-footer-bg {
  width: 100%;
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-top: 4px solid rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 -1px 15px 0 rgba(0, 0, 0, 0.5) !important;
}
.visitor-footer {
  max-width: 1700px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

/*=====================================================*/
/* input 스타일 */
.visitor-slider .slick-prev:before {
  color: #fff;
  content: "<";
  font-family: "GmarketSansMedium";
  border: 1px solid #fff;
  border-radius: 9999px;
  padding: 7px 14px 4px 14px;
  font-size: 26px !important;
  background-color: #000;
}

.visitor-slider .slick-next:before {
  color: #fff;
  content: ">";
  font-family: "GmarketSansMedium";
  border: 1px solid #fff;
  border-radius: 9999px;
  padding: 7px 14px 4px 14px;
  font-size: 26px !important;
  background-color: #000;
}

/*=====================================================*/
/* input 스타일 */
.visitor-form .form-control {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #ddd;
  background-color: transparent !important;
}

.visitor-form .form-group:focus-within {
  color: #5395ff !important;
  transition: ease-in-out 0.2s;
}

/*=====================================================*/
.keep-words {
  word-break: keep-all !important;
}

.rounded-visitor-market {
  border-radius: 80px !important;
}

.rounded-r-visitor-market {
  border-top-right-radius: 80px !important;
  border-bottom-right-radius: 80px !important;
}

/*=====================================================*/
/* Ani-btn */
.visitor-card-hover {
  border-radius: 10px;
}

.visitor-card-hover:hover {
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

/*=====================================================*/
/* Ani-btn */
.ani-btn {
  position: relative;
  margin: auto;
  padding: 19px 22px;
  transition: all 0.2s ease;
  width: max-content;
}

.ani-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: #5395ff;
  opacity: 0.2;
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
}

.ani-btn span:before {
  position: relative;
  content: "체험 하기";
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  letter-spacing: 0.25em;
  vertical-align: middle;
  font-family: "GmarketSansMedium" !important;
  transition: all 0.5s ease;
}

.ani-btn > i {
  position: relative;
  top: 0;
  margin-left: 10px;
  stroke-width: 2;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.ani-btn:hover:before {
  width: 100%;
  background: #5395ff;
  border-radius: 0;
  opacity: 1;
}

.ani-btn:hover span:before {
  color: #fff;
  content: "회원 가입 후 체험해 보세요!";
  transition: all 0.5s ease;
}

.ani-btn:hover > i {
  transform: translateX(0);
  color: #fff;
}

.ani-btn:active {
  transform: scale(0.96);
}

/* Ani-btn */
.about-btn {
  position: relative;
  padding: 19px 22px;
  transition: all 0.2s ease;
  width: max-content;
  display: flex;
  align-items: center;
  margin: auto;
}

.about-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: #5395ff;
  opacity: 0.2;
  width: 56px;
  height: 56px;
  transition: all 0.5s ease;
}

.about-btn span {
  position: relative;
  font-size: 16px;
  padding-top: 2px;
  font-family: "GmarketSansMedium" !important;
  font-weight: bold !important;
  transition: all 0.5s ease;
}

.about-btn:hover span {
  color: #fff;
  transition: all 0.5s ease;
}

.about-btn > i {
  position: relative;
  margin-left: 20px;
  stroke-width: 2;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.about-btn:hover {
  width: max-content;
  background: #5395ff;
  border-radius: 10px;
  opacity: 1;
}

.about-btn:hover span:before {
  color: #fff;
  transition: all 0.5s ease;
}

.about-btn:hover > i {
  transform: translateX(0);
  color: #fff;
  transition: all 0.5s ease;
}

.about-btn:active {
  transform: scale(0.96);
}

.enkino-red {
  color: #fe0000 !important;
}

/*=====================================================*/
/*=====================================================*/
/* media-Quary */
@media (max-width: 1200px) {
  .page-1-h {
    height: calc(100vh - 120px);
  }

  /*=====================================================*/
  /* mo-title */
  .first-page-content > div > h1,
  .page-content > h1 {
    font-size: 60px;
  }

  .first-page-content > div > h4,
  .page-content > h4 {
    font-size: 22px !important;
  }

  .page-content ul li div h2 {
    font-size: 30px;
  }

  .page-content ul li div h2 {
    font-size: 30px;
  }
}

@media (max-width: 1440px) {
  .sub_title {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .page-1-h {
    height: calc(100vh - 70px);
  }

  .first-page-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 640px) {
  /*=====================================================*/
  /* mo-title */
  .first-page-content > div h1 {
    font-size: 30px;
  }

  .page-content h1,
  .mo-text h1 {
    font-size: 40px;
  }

  .first-page-content > div h4 {
    font-size: 16px !important;
  }

  .page-content h4,
  .mo-text h4 {
    font-size: 18px !important;
  }

  .page-content ul li div h2 {
    font-size: 30px;
  }

  .page-content ul li div h2 {
    font-size: 30px;
  }

  .rounded-visitor-market {
    border-radius: 50px !important;
  }
}
