/**
 * /*--------------------
 * Checkout
 * --------------------
 *
 * @format
 */

.checkout {
  margin: 150px auto 0;
  position: relative;
  width: 100%;
  max-width: 45%;
  background: white;
  border-radius: 15px;
  padding: 160px 45px 30px;
  box-shadow: 0 10px 40px hsla(0, 0, 0, 0.1);
  @media (max-width: 1200px) {
    max-width: 400px;
  }
}

/*--------------------
Credit Card
--------------------*/
.credit-card-box {
  perspective: 1000;
  width: 90%;
  height: 280px;
  position: absolute;
  top: -112px;
  left: 50%;
  transform: translateX(-50%);

  &:hover .flip,
  &.hover .flip {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    width: 100%;
    height: 250px;
    border-radius: 15px;
    backface-visibility: hidden;
    background: linear-gradient(135deg, #5395ff, #2c6fda);
    position: absolute;
    color: #fff;
    font-family: Inconsolata;
    top: 0;
    left: 0;
    text-shadow: 0 1px 1px hsla(0, 0, 0, 0.3);
    box-shadow: 0 1px 6px hsla(0, 0, 0, 0.3);
    @media (max-width: 430px) {
      height: 200px;
      border-radius: 10px;
    }
    @media (max-width: 330px) {
      height: 170px;
      border-radius: 10px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // background: url("http://cdn.flaticon.com/svg/44/44386.svg") no-repeat center;
      background-size: cover;
      opacity: 0.05;
    }
  }

  .flip {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }

  .logo {
    position: absolute;
    top: 9px;
    right: 20px;
    width: 60px;

    @media (max-width: 430px) {
      top: 3px;
      width: 40px;
    }
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);

    .logo {
      top: 185px;
      @media (max-width: 430px) {
        top: 160px;
      }
      @media (max-width: 330px) {
        top: 140px;
      }
    }
  }

  .chip {
    position: absolute;
    width: 60px;
    height: 45px;
    top: 20px;
    left: 20px;
    background: linear-gradient(135deg, hsl(269, 54%, 87%) 0%, hsl(200, 64%, 89%) 44%, hsl(18, 55%, 94%) 100%);
    border-radius: 8px;
    @media (max-width: 430px) {
      width: 50px;
      height: 35px;
      border-radius: 5px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border: 4px solid hsla(0, 0, 50, 0.1);
      width: 80%;
      height: 70%;
      border-radius: 5px;
    }
  }

  .strip {
    background: linear-gradient(135deg, hsl(0, 0, 25%), hsl(0, 0, 10%));
    position: absolute;
    width: 100%;
    height: 50px;
    top: 30px;
    left: 0;
    @media (max-width: 430px) {
      height: 40px;
    }
    @media (max-width: 330px) {
      height: 30px;
    }
  }

  .number {
    position: absolute;
    margin: 0 auto;
    top: 130px;
    left: 19px;
    font-size: 25px;
    font-weight: bold;
    @media (max-width: 430px) {
      top: 100px;
      font-size: 20px;
    }
    @media (max-width: 330px) {
      top: 80px;
      font-size: 16px;
    }
  }

  label {
    font-size: 10px;
    letter-spacing: 1px;
    text-shadow: none;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0.5;
    display: block;
    margin-bottom: 3px;
    @media (max-width: 430px) {
      font-size: 8px;
    }
  }

  .card-holder,
  .card-expiration-date {
    position: absolute;
    margin: 0 auto;
    top: 180px;
    left: 19px;
    font-size: 22px;
    text-transform: capitalize;
    @media (max-width: 430px) {
      top: 150px;
      font-size: 14px;
    }
    @media (max-width: 330px) {
      top: 120px;
    }
  }
  .card-expiration-date {
    text-align: right;
    left: auto;
    right: 20px;
  }

  .ccv {
    height: 36px;
    background: #fff;
    width: 91%;
    border-radius: 5px;
    top: 110px;
    left: 0;
    right: 0;
    position: absolute;
    margin: 0 auto;
    color: #000;
    text-align: right;
    padding: 10px;
    @media (max-width: 430px) {
      height: 30px;
      top: 100px;
      padding: 5px;
    }
    @media (max-width: 330px) {
      height: 25px;
      top: 90px;
    }

    label {
      margin: -25px 0 14px;
      color: #fff;
    }
  }
}

.the-most {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 50vw;
  max-width: 200px;
  padding: 10px;

  img {
    max-width: 100%;
  }
}
