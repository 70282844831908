/** @format */

/*=====================================================*/
input {
  font-size: 14px !important;
}
/*=== 에디터 ===*/
.tox-tinymce {
  border: 1px solid #eee !important;
  border-radius: 5px !important;
}
.tox .tox-toolbar__primary,
.tox .tox-menubar,
.tox .tox-toolbar,
.tox .tox-statusbar {
  background-color: #f6f9fc !important;
}

/* 모달 위 드롭 메뉴 보이게 하기 */
.tox.tox-tinymce-aux,
.tox-fullscreen .tox.tox-tinymce-aux {
  z-index: 100000;
}

/*=====================================================*/
/*=== MTRIAL 텍스트 입력 인풋 ===*/
/* 라벨 */
.form-wizard-vertical-code-app > div > ol {
  padding: 0 !important;
}
/*=====================================================*/
/*=== MTRIAL 텍스트 입력 인풋 ===*/
/* 라벨 */

.MuiFormLabel-root {
  color: #555 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  font-family: "GmarketSansMedium" !important;
  padding-top: 0 !important;
}
.col-form-label {
  color: #555 !important;
  font-weight: 500 !important;
  font-size: 13.5px !important;
  font-family: "GmarketSansMedium" !important;
  padding-top: 0 !important;
}
.MuiInputLabel-shrink {
  transform: translate(0, -10.5px) scale(0.75) !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #5395ff !important;
}
/* 텍스트 입력 인풋 */
.MuiInputBase-input {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}
.MuiDialog-paperScrollPaper {
  background-color: #fff !important;
}
.MuiInput-underline:before {
  border-bottom: 0px solid #5395ff !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid #5395ff !important;
}
/* Select */
/* User Select */
.select-user-input {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f9fc !important;
  border-radius: 3px;
  width: 100%;
  height: 48.63px;
}
.select-user-input > p {
  padding: 0.9rem 1.5rem !important;
  width: calc(100% - 50px);
  overflow: hidden;
}
/* Market Search Area Input */
.search-input .MuiInputBase-root {
  border-radius: 10px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
}
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
.MuiInputBase-root:focus,
.MuiInputBase-root.Mui-focused,
.MuiOutlinedInput-root:focus,
.MuiOutlinedInput-root.Mui-focused,
.Mui-focused {
  border-color: none !important;
  border-radius: 10px !important;
  background: #fff !important;
  transition: ease-in-out 0.2s;
}
.search-input .MuiInputBase-input {
  background-color: transparent !important;
}
/* User Select End */
.MuiSelect-select:focus {
  background-color: #f6f9fc !important;
}
.MuiInputBase-root {
  border-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 2px solid rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 99px !important;
  font-family: "GmarketSansMedium";
}
.MuiSelect-select.MuiSelect-select {
  display: flex;
}
.MuiSelect-select.MuiSelect-select {
  /* width: calc(100% - 45px); */
  width: calc(100%);
}
.MuiSelect-select.MuiSelect-select > i {
  display: none;
}
.MuiFormControl-root {
  margin: auto 0 !important;
}
.MuiSelect-icon {
  color: #000 !important;
  margin-right: 10px;
}
/* 클리어 */
.MuiAutocomplete-endAdornment {
  right: 10px !important;
}
/* select */
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #f6f9fc !important;
}

.MuiChip-root {
  background-color: #fff !important;
}
.MuiChip-label {
  color: #555;
}
.MuiChip-deleteIcon {
  color: #5395ff !important;
}
.MuiChip-deleteIcon:hover {
  color: #1e66d8 !important;
  transition: ease-in-out 0.2s;
}

/*=====================================================*/
/*=== DotMenu STYLE ===*/
.MuiList-padding {
  padding: 5px 0 !important;
}
.MuiMenu-list {
  border: 1px solid #eee;
  max-height: 300px !important;
  min-width: 240px !important;
}
.MuiMenuItem-root {
  padding: 0.8rem 1.2rem !important;
  font-size: 13px !important;
}
.admin-home-menu .MuiListSubheader-gutters {
  padding: 0 0 0.5rem 0 !important;
}
.admin-home-menu .MuiListItem-root {
  padding: 0.5rem 0.5rem;
}
.admin-home-menu .MuiListItemIcon-root {
  color: #333 !important;
  min-width: 40px;
}
.admin-home-menu .MuiTypography-body1 {
  font-size: 13px;
  color: #888;
}
.MuiListItem-button:hover {
  background-color: #e8edf1 !important;
  font-weight: bold !important;
}

.MuiListItem-button:hover > i,
.MuiListItem-button:hover > p {
  color: #5395ff !important;
}
.admin-home-menu .MuiListItem-button:hover .MuiListItemIcon-root {
  color: #555 !important;
}
.admin-home-menu .MuiListItem-button:hover .MuiTypography-body1 {
  font-weight: bold;
  color: #333 !important;
}
.admin-home-menu .MuiListItem-button:hover .number-p {
  font-weight: bold;
  color: #333 !important;
}
.admin-home-menu .MuiListItem-button:hover .new-p {
  color: #fff !important;
}

.list-title-text .MuiTypography-body1 {
  font-weight: bold !important;
  color: #555;
  font-size: 13.5px !important;
}
/* 그림자 */
.MuiPaper-elevation8 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

/* 버튼 패딩 */
.MuiIconButton-root {
  padding: 5px !important;
  /* margin-right: 5px !important; */
}

/*=====================================================*/
.MuiInputBase-root.MuiInputBase-multiline.MuiInput-multiline {
  border-radius: 10px !important;
}
/*=== Textarea STYLE ===*/
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #5395ff !important;
}
.MuiButton-textPrimary {
  color: #5395ff !important;
}

/*=====================================================*/
/*=== Textarea STYLE ===*/
textarea {
  background-color: #f6f9fc !important;
}
/*=====================================================*/
/*=== Select Box STYLE ===*/
.css-yk16xz-control {
  border-color: #eee !important;
  border-radius: 2px;
  height: 3.175rem;
}
.css-1pahdxg-control {
  height: 3.175rem;
  border-color: rgba(59, 130, 246, 0.5) !important;
  box-shadow: none !important;
}
.css-g1d714-ValueContainer {
  padding: 2px 1.94rem !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-1wa3eu0-placeholder {
  color: #ccc !important;
  font-size: 0.875rem;
}
.css-1uccc91-singleValue {
  font-size: 0.875rem;
}
.css-9gakcf-option,
.css-yt9ioa-option {
  padding: 1rem 1.5rem !important;
  font-size: 14px !important;
}
.css-9gakcf-option:hover,
.css-yt9ioa-option:hover {
  background-color: #f8f9fa !important;
  color: #5395ff !important;
  padding: 1rem 1.5rem !important;
  font-size: 14px !important;
}

/*=====================================================*/
/*=========== React Datepicker ========== */
.react-datepicker {
  font-family: "Nanum Gothic" !important;
  font-weight: bold;
}
.react-datepicker__input-container > .admin-card-in-dateInput {
  color: #555 !important;
  font-weight: bold;
  font-size: 13px !important;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 3px;
  text-align: center;
  background-color: #f6f9fc;
  width: 100%;
  cursor: pointer;
}
/* Datepicker Header */
.react-datepicker__header {
  background-color: #5395ff !important;
  border-bottom: 0 !important;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  padding: 1rem 0 0 0 !important;
  color: #fff !important;
  font-weight: black;
}
.react-datepicker__navigation {
  top: 18px !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff;
}
/* Datepicker Body */
.react-datepicker__month {
  background-color: #fff !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2.4rem;
  line-height: 2.5rem;
}
.react-datepicker__day {
  color: #888;
}
.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
  padding: 1px !important;
  margin: 10px 5px;
}
.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected {
  background-color: #e7f0ff;
  border-radius: 9999px;
  color: #fff !important;
}
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: #e7f0ff;
  border-radius: 9999px;
  color: #333 !important;
}
.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range {
  background-color: #e7f0ff;
  border-radius: 9999px;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #5395ff;
  border-radius: 9999px;
  color: #fff !important;
}
.react-datepicker__day:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #5395ff;
  border-radius: 9999px;
  color: #fff !important;
}
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--selected::before {
  background: #5395ff !important;
  width: 100% !important;
  height: 100% !important;
}
/*=========== 이전 다음 버튼 ==========*/
.react-datepicker__triangle {
  display: none;
  border-bottom-color: #fff !important;
}
.react-datepicker__portal .react-datepicker__navigation {
  border: 0.45rem solid transparent;
}
/* 이전으로 */
.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #fff !important;
}
.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #fff !important;
  opacity: 1;
}
/* 다음으로 */
.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #fff !important;
}
.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #fff !important;
  opacity: 1;
}

/*=====================================================*/
/*=========== 월 선택 ==========*/
.month-select .react-datepicker .react-datepicker__month-container .react-datepicker__header {
  height: 80px;
  display: flex;
  flex-flow: column w;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 !important;
  font-size: 20px;
  font-weight: 900;
}
.month-select .react-datepicker__navigation {
  top: 34px !important;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding: 17px 0 !important;
  border-radius: 9999px;
  font-size: 14px;
  width: 50px !important;
  margin: 5px;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #e3edff !important;
  transition: ease-in-out 0.3s;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #5395ff !important;
}
.month-select > div > .react-datepicker-wrapper > .react-datepicker__input-container {
  width: max-content !important;
}
.month-select .react-datepicker-wrapper > .react-datepicker__input-container > input {
  font-weight: 900;
  font-size: 24px !important;
  padding: 7px;
  color: #5395ff;
  width: 80px;
  text-align: center;
  padding-bottom: 10px;
  background-color: #f6f9fc;
  border-radius: 5px;
}
/*=========== 월 선택 ==========*/
.basic-datepicker .react-datepicker__today-button {
  background: #f6f9fc;
  color: #5395ff;
  padding: 10px 0;
  border: 0;
}
.basic-datepicker .react-datepicker-wrapper > .react-datepicker__input-container > input {
  font-weight: 900;
  font-size: 24px !important;
  padding: 7px;
  color: #5395ff;
  text-align: center;
  padding-bottom: 10px;
  background-color: #f6f9fc;
  border-radius: 5px;
}
