/*=====================================================*/
/*========== 공통탭 ==========*/
.aiso-tab {
  backdrop-filter: blur(4px) saturate(200%) !important;
  -webkit-backdrop-filter: blur(4px) saturate(200%) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border: 4px solid rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5) !important;
  border-radius: 20px;
  min-height: 73vh;
}
.aiso-tab > .tab-content {
  padding: 2rem 1rem !important;
}
.aiso-tab > .nav-tabs .nav-link,
.aiso-tab > .nav .nav-item > .nav-link {
  border: none !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-bottom: 4px solid rgba(255, 255, 255, 0.4) !important;
  text-align: center;
  padding: 1.1rem 1.5rem !important;
  font-size: 16px !important;
  cursor: pointer;
}
.aiso-tab > .nav-tabs .nav-link:first-of-type,
.aiso-tab > .nav .nav-item:first-of-type > .nav-link:first-of-type {
  border-top-left-radius: 17px;
}
.aiso-tab > .nav-tabs .nav-link:last-of-type,
.aiso-tab > .nav .nav-item:last-of-type > .nav-link:last-of-type {
  border-top-right-radius: 17px;
}
.nav-tabs .nav-link.active,
.aiso-tab > .nav .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #5395ff !important;
  font-weight: bold;
}
.nav-tabs .danger-nav > .nav-link.active,
.aiso-tab > .nav .danger-nav > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #f96868 !important;
  font-weight: bold;
}
/*=====================================================*/
/*========== 탭 calc ==========*/
.tab-1 > .nav-tabs .nav-link,
.tab-1 > .nav .nav-item {
  width: 100%;
}
.tab-2 > .nav-tabs .nav-link,
.tab-2 > .nav .nav-item {
  width: calc(100% / 2);
}
.tab-3 > .nav-tabs .nav-link,
.tab-3 > .nav .nav-item {
  width: calc(100% / 3);
}
.tab-4 > .nav-tabs .nav-link,
.tab-4 > .nav .nav-item {
  width: calc(100% / 4);
}
.tab-5 > .nav-tabs .nav-link,
.tab-5 > .nav .nav-item {
  width: calc(100% / 5);
}
.tab-6 > .nav-tabs .nav-link,
.tab-6 > .nav .nav-item {
  width: calc(100% / 6);
}

/*=====================================================*/
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 767px) {
  .aiso-tab > .nav {
    background-color: rgb(240, 241, 245);
  }
  .aiso-tab > .nav-tabs .nav-link,
  .aiso-tab > .nav .nav-item > .nav-link {
    border: 1px solid #fff !important;
  }
  .tab-3 > .nav-tabs .nav-link,
  .tab-3 > .nav .nav-item {
    width: calc(100% / 2);
  }
  .tab-4 > .nav-tabs .nav-link,
  .tab-4 > .nav .nav-item {
    width: calc(100% / 2);
  }
  .tab-5 > .nav-tabs .nav-link,
  .tab-5 > .nav .nav-item {
    width: calc(100% / 2);
  }
  .tab-6 > .nav-tabs .nav-link,
  .tab-6 > .nav .nav-item {
    width: calc(100% / 2);
  }
}
@media (max-width: 320px) {
  .tab-2 > .nav-tabs .nav-link,
  .tab-2 > .nav .nav-item {
    width: 100%;
  }
  .tab-3 > .nav-tabs .nav-link,
  .tab-3 > .nav .nav-item {
    width: 100%;
  }
  .tab-4 > .nav-tabs .nav-link,
  .tab-4 > .nav .nav-item {
    width: 100%;
  }
  .tab-5 > .nav-tabs .nav-link,
  .tab-5 > .nav .nav-item {
    width: 100%;
  }
  .tab-6 > .nav-tabs .nav-link,
  .tab-6 > .nav .nav-item {
    width: 100%;
  }
}
