/** @format */

select.form-control > option {
  background-color: #fff;
}
._eitable > a {
  color: #333;
  font-weight: 500;
}
.float-right>._eitable>div{
  display: flex !important;
  align-items: center !important;
}
._eitable > div > input {
  height: 3em;
  border-radius: 0;
  background-color: #fff;
  border-radius: .5rem;
}
._eitable > div > button.btn-success {
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 9px 7px !important;
}
._eitable > div > button.btn-success > svg {
  fill: #5395ff !important;
  
}
._eitable > div > button.btn-success:hover {
  background-color: #5395ff !important;
  border-color: #5395ff !important;
}
._eitable > div > button.btn-success:hover > svg {
  fill: #fff !important;
}

._eitable > div > button.btn-danger {
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 9px 8px !important;
}
._eitable > div > button.btn-danger > svg {
  fill: #f96868 !important;
}
._eitable > div > button.btn-danger:hover {
  background-color: #f96868 !important;
  border-color: #f96868 !important;
}
._eitable > div > button.btn-danger:hover > svg {
  fill: #fff !important;
}
