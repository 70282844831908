/** @format */

.btn-scroll-top > i {
  color: #5395ff;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 900;
  margin-bottom: 3px;
  /* animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
}
.btn-scroll-top {
  background-color: #fff;
  box-shadow: 0 0px 10px 0px rgba(25, 144, 255, 0.5);
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 45px;
  width: 45px;
  background: #fff;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 100%;
  /* -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both; */
}

.btn-scroll-top:hover {
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 0 0px 10px 0px rgba(25, 144, 255, 0.8);
}
