/**
 * /* Dropdowns
 *
 * @format
 */

.dropdown {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "themify";
      content: "\e64b";
      width: auto;
      height: auto;
      vertical-align: baseline;
      line-height: 0.625rem;
      font-size: 0.875rem;
      margin-left: 0.3rem;
      .rtl & {
        margin-left: 0;
        margin-right: 0.255em;
      }
    }
  }
  .dropdown-menu {
    margin-top: 0;
    font-size: $default-font-size;
    box-shadow: 0px 1px 5px 1px rgba(197, 197, 197, 0.35);
    border: 1px solid #eee;
    border-radius: 3px;
    .rtl & {
      text-align: right;
    }
    .dropdown-item {
      font-size: 13px;
      padding: 0.85rem 1.5rem;
      color: #555;
      font-weight: 500;
      cursor: pointer;
      &:active {
        background: #eee;
      }
    }
  }
}
