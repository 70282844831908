/* Market Search Area */
.search-input {
  width: 100%;
}
.search-input .MuiFormControl-root,
.search-input .MuiFormControl-root .MuiInputBase-root {
  height: 100%;
}
.search-input .MuiInputBase-input {
  box-sizing: inherit !important;
  font-size: 14px !important;
}
.search-input .MuiInputBase-input::placeholder {
  color: #000 !important;
}

.search-label {
  width: 100%;
  margin-bottom: 0;
}

/*=====================================================*/
.search-label > .form-control {
  width: 100%;
  height: 50px;
  padding-left: 64px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 2px solid rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 99px !important;
  font-family: "GmarketSansMedium";
}
.search-label > .form-control::placeholder {
  color: #888 !important;
  font-family: "GmarketSansMedium";
}
.gray-search-box {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 2px solid rgba(255, 255, 255, 0.7) !important;
}
.border-search-box {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 2px solid rgba(255, 255, 255, 0.7) !important;
}
.search-label > .form-control.active,
.search-label > .form-control::selection,
.search-label > .form-control:active,
.search-label > .form-control:focus {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.search-area {
  position: relative;
}
.search-area > i {
  position: absolute;
  top: 17px;
  left: 20px;
  color: #888;
}
.search-area > .clear-btn {
  position: absolute !important;
  top: 10px;
  right: 10px;
  width: auto !important;
  font-weight: 500;
  font-size: 14px !important;
  color: #333 !important;
  background-color: #fff;
  padding: 2px 10px !important;
  border-radius: 9999px;
}
.search-area > .clear-btn-2 {
  position: absolute !important;
  top: 10px;
  right: 10px;
  width: auto !important;
  font-weight: 500;
  font-size: 12px !important;
  color: #888 !important;
  background-color: #fff;
  padding: 8px 15px !important;
  border-radius: 9999px;
}
/*=====================================================*/
.sarch-area-btn {
  background-color: #fff !important;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
}
.sarch-area-btn:hover {
  transition: all 0.3s !important;
  color: #5395ff !important;
}
.search-white-area-btn {
  /* background-color: #d7e7ff !important; */
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5395ff;
}
.search-white-area-btn:hover {
  background-color: #5395ff !important;
  color: #fff !important;
  transition: all 0.3s !important;
}
