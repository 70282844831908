/* Dot opacity loader */

.dot-opacity-loader {
  width: 200px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.dot-opacity-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3) !important;
  margin: 35px 3px;
  opacity: 0;
}

.dot-opacity-loader span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}

.dot-opacity-loader span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.dot-opacity-loader span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}
