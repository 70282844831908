/**
 * /* Tables
 *
 * @format
 */

.table {
  width: 100%;
  // white-space: nowrap;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: $font-weight-bold;
      color: #888 !important;
      font-size: 13px !important;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;

    img {
      width: 100%;
      height: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
}
